:root {
    //Default colors
    --default-bg: #f6f6f6;
    --default-accent: #4173F4;
    --default-text-color: #21204A;
    --default-blue: #2947D3;

    //Colors
    --text-gray: #8d8d8d;
    --text-grafit: #89899E;
    --text-black: #000000;
    --border-gray: #e4e4e4;
    --text-white: #ffffff;
    --text-shadow: #212121;

    --red: #DF0012;

    //Margins
    --margin-8: 8px;
    --margin-12: 12px;
    --margin-24: 24px;
    
}